import "core-js/modules/es.string.ends-with.js";
import { useStorage } from "@vueuse/core";
import useBaseUtils from "~/functions/useBaseUtils";
export default {
  __name: 'ForecastYearsSelect',
  setup: function setup(__props) {
    var amountOfYearsAhead = useStorage("hubAmountOfYearsAhead", 3);
    var _useBaseUtils = useBaseUtils(),
      customGroup = _useBaseUtils.customGroup;
    var sortingOptions = computed(function () {
      var isFreeTier = customGroup.value.endsWith("-free");
      var isPlusTier = customGroup.value.endsWith("-plus");
      var isProTier = !isFreeTier && !isPlusTier;
      return [{
        label: "1 Yr Forecast",
        value: 1
      }, {
        label: "2 Yr Forecast",
        value: 2
      }, {
        label: "3 Yr Forecast",
        value: 3,
        lock: isFreeTier
      }, {
        label: "4 Yr Forecast",
        value: 4,
        lock: !isProTier
      }, {
        label: "5 Yr Forecast",
        value: 5,
        lock: !isProTier
      }];
    });
    var handleMenuChange = function handleMenuChange(newValue) {
      amountOfYearsAhead.value = newValue;
    };
    return {
      __sfc: true,
      amountOfYearsAhead: amountOfYearsAhead,
      customGroup: customGroup,
      sortingOptions: sortingOptions,
      handleMenuChange: handleMenuChange
    };
  }
};